<template>
  <div>
    <v-dialog v-model="showStudioDataDialog" width="100vw">
      <v-card>
        <div class="modal-header header-div">
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" flat icon size="x-small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <StudioData @save-chart="handleChartSavedStudio" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form>
      <ScreenLoader v-if="showLoader == true"></ScreenLoader>
      <v-row
        class="chart-opts dashboard-top"
        no-gutters
        v-else-if="
          Array.isArray(this.dashboardsArr) && this.dashboardsArr.length != 0
        "
      >
        <v-col cols="12" md="6" class="pa-0">
          <label for="">Select Saved Dashboard</label>
          <v-select
            v-model="dashboard"
            :items="dashboardName"
            item-text="Name"
            @update:modelValue="() => changeDashboard()"
            placeholder="Select Dashboard"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 d-flex justify-end">
          <v-btn
            prepend-icon="mdi-plus"
            variant
            class="save-btn dashboard-btn text-none"
            @click="dashboardCreate()"
          >
            Create New Dashboard
          </v-btn>
        </v-col>
      </v-row>
      <div
        v-else-if="
          !Array.isArray(this.dashboardsArr) || this.dashboardsArr.length == 0
        "
        class="text-center pt-5"
      >
        <h4>No dashboard available</h4>
      </div>
      <div v-else-if="timeoutMessage != ''" class="timeout">
        <div class="text-center">
          {{ timeoutMessage }}
          <v-icon
            flat
            @click="getSavedDashboards()"
            color="blue-darken-3"
            icon="mdi-reload"
            size="large"
          ></v-icon>
        </div>
      </div>
      <ChartLoaders v-if="showChartsLoader == true"></ChartLoaders>
      <BoardSelected v-else-if="showDashboard" ref="childComponentRef" @dashboard-reload="reloadAfterDelete" @dashboard-name-update="nameUpdate"/>
      <div
        class="pb-3 mt-4 chart-selection-wrap"
        v-else-if="showInputs && Array.isArray(chartTypes) && chartTypes.length != 0"
      >
        <h6 class="main-heading mb-2">Create Dashboard</h6>
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 pb-5">
            <label for="">Enter Dashboard Name</label>
            <v-text-field
              v-model="name"
              required
              placeholder="Enter Name"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_one"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select Chart"
              @update:modelValue="() => chartTypeChange(chart_one)"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_two"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select Chart"
              @update:modelValue="() => chartTypeChange(chart_two)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_three"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select saved chart"
              @update:modelValue="() => chartTypeChange(chart_three)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_four"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select Chart"
              @update:modelValue="() => chartTypeChange(chart_four)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_five"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select Chart"
              @update:modelValue="() => chartTypeChange(chart_five)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pa-0 pl-md-2 py-md-0">
            <label for="">Select Saved Chart</label>
            <v-select
              clearable
              v-model="chart_six"
              :items="chartTypesName"
              item-value="ID"
              item-text="Name"
              placeholder="Select Chart"
              @update:modelValue="() => chartTypeChange(chart_six)"
            ></v-select>
          </v-col>
        </v-row>
        <div class="save-dash">
          <v-btn
          v-if="showInputs"
            block
            flat
            @click="openDialog()"
            :disabled="isSaveDisabled"
            class="save-btn text-none"
          >
            <img :src="saveDashboard" alt="save_dashboard" />
            <span>Save Dashboard Settings</span></v-btn
          >
        </div>
      </div>
      <div
        v-else-if="!Array.isArray(chartTypes) || chartTypes.length == 0"
        class="no-test-div text-center"
      >
        <h4>
          No charts found. <br />
          please create charts first from
          <router-link to="/Fluidsdata-studio" exact>
            <span>Fluidsdata studio page</span>
          </router-link>
        </h4>
      </div>
      <div v-else-if="timeoutMessageCharts != ''" class="no-asset-div timeout">
        <div class="text-center">
          {{ timeoutMessageCharts }}
          <v-icon
            flat
            @click="getSavedCharts()"
            color="blue-darken-3"
            icon="mdi-reload"
            size="large"
          ></v-icon>
        </div>
      </div>
      <!-- <div
        v-else-if="
        Array.isArray(chartTypes) &&
          showLoader == false &&
          showChartsLoader == false &&
          timeoutMessage == '' &&
          timeoutMessageCharts == '' &&
          chartTypes.length == 0
        "
        class="no-asset-div text-center"
      >
        <h4>
          No charts found. <br />
          please create charts first from
          <router-link to="/fluidsdata-studio" exact>
            <span>fluidsdata studio page</span>
          </router-link>
        </h4>
      </div> -->
    </v-form>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
    <ConfirmDashboard
      ref="childComponent"
      @open-dialog="openDialog"
      :selectedChartIds="selectedChartIds"
      :name="name"
      @dashboard-saved="reloadDashboards"
    />
  </div>
</template>

<script>
import axios from "axios";
import api from "../../../axiosInterceptor";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import ChartLoaders from "../Loaders/ChartLoaders.vue";
import BoardSelected from "./BoardSelected.vue";
import ConfirmDashboard from "./ConfirmDashboard.vue";
import StudioData from '../FluidsDataStudio/StudioData.vue';
export default {
  components: { ScreenLoader, ChartLoaders, BoardSelected, ConfirmDashboard, StudioData },
  name: "ChartInputs",
  data() {
    return {
      dashboardsArr: [],
      selectedChartIds: [],
      dashboardName: [],
      name: "",
      chart_one: null,
      chart_two: null,
      chart_three: null,
      chart_four: null,
      chart_five: null,
      chart_six: null,
      chartTypes: [],
      chartTypesName: [],
      loading: false,
      showAlert: false,
      errorMessage: "",
      dashboard: null,
      showLoader: true,
      showChartsLoader: false,
      timeoutMessage: "",
      timeoutMessageCharts: "",
      showDashboard: false,
      saveDashboard: require("../../../assets/Images/Dashboard/save.png"),  
      showInputs: true,

      showStudioDataDialog: false,
    };
  },
  computed: {
    isSaveDisabled() {
      return (
        !this.name ||
        !(
          this.chart_one ||
          this.chart_two ||
          this.chart_three ||
          this.chart_four ||
          this.chart_five ||
          this.chart_six
        )
      );
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
    handleChartSavedStudio() {
      this.closeModal();
      this.getSavedCharts();
    },
    getSavedCharts() {
      this.showChartsLoader = true;
      this.timeoutMessageCharts = "";
      api
        .get("public/api/v1/charts")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.charts;
            // this.chartTypesName = this.chartTypes.map((chart) => chart.Name);
            this.chartTypesName = [
              ...this.chartTypes.map((chart) => chart.Name),
              "+ Create New Chart"
            ];
          }
        })
        .catch((error) => {
          this.timeoutMessageCharts = error;
        })
        .finally(() => {
          this.showChartsLoader = false;
        });
    },

    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards.map(
              (dashboard) => dashboard.name
            );
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          1;
          this.showLoader = false;
        });
    },

    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },

    // chartTypeChange(selectedChart) {
    //   // Reset selectedChartIds array
    //   this.selectedChartIds = [];

    //   // Add ID of selected chart for chart_one
    //   if (this.chart_one) {
    //     const chartOne = this.chartTypes.find(
    //       (chart) => chart.Name === this.chart_one
    //     );
    //     if (chartOne) {
    //       this.updateSelectedChartIds(chartOne.ID);
    //     }
    //   }

    //   // Remove selected charts from chartTypes for chart_two options
    //   let availableChartsForChartTwo = this.chartTypes
    //     .filter((chart) => {
    //       return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
    //     })
    //     .map((chart) => {
    //       return { ID: chart.ID, Name: chart.Name };
    //     });

    //   // Update chartTypesName for chart_two dropdown
    //   this.chartTypesName = availableChartsForChartTwo.map(
    //     (chart) => chart.Name
    //   );

    //   // Add ID of selected chart for chart_two
    //   if (this.chart_two) {
    //     const chartTwo = availableChartsForChartTwo.find(
    //       (chart) => chart.Name === this.chart_two
    //     );
    //     if (chartTwo) {
    //       this.updateSelectedChartIds(chartTwo.ID);
    //     }
    //   }

    //   // Remove selected charts from chartTypes for chart_three options
    //   let availableChartsForChartThree = availableChartsForChartTwo
    //     .filter((chart) => {
    //       return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
    //     })
    //     .map((chart) => {
    //       return { ID: chart.ID, Name: chart.Name };
    //     });

    //   // Update chartTypesName for chart_three dropdown
    //   this.chartTypesName = availableChartsForChartThree.map(
    //     (chart) => chart.Name
    //   );

    //   // Add ID of selected chart for chart_three
    //   if (this.chart_three) {
    //     const chartThree = availableChartsForChartThree.find(
    //       (chart) => chart.Name === this.chart_three
    //     );
    //     if (chartThree) {
    //       this.updateSelectedChartIds(chartThree.ID);
    //     }
    //   }

    //   let availableChartsForChartFour = availableChartsForChartThree
    //     .filter((chart) => {
    //       return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
    //     })
    //     .map((chart) => {
    //       return { ID: chart.ID, Name: chart.Name };
    //     });

    //   // Update chartTypesName for chart_three dropdown
    //   this.chartTypesName = availableChartsForChartFour.map(
    //     (chart) => chart.Name
    //   );

    //   // Add ID of selected chart for chart_three
    //   if (this.chart_four) {
    //     const chartFour = availableChartsForChartFour.find(
    //       (chart) => chart.Name === this.chart_four
    //     );
    //     if (chartFour) {
    //       this.updateSelectedChartIds(chartFour.ID);
    //     }
    //   }

    //   let availableChartsForChartFive = availableChartsForChartFour
    //     .filter((chart) => {
    //       return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
    //     })
    //     .map((chart) => {
    //       return { ID: chart.ID, Name: chart.Name };
    //     });

    //   // Update chartTypesName for chart_three dropdown
    //   this.chartTypesName = availableChartsForChartFive.map(
    //     (chart) => chart.Name
    //   );

    //   // Add ID of selected chart for chart_three
    //   if (this.chart_five) {
    //     const chartFive = availableChartsForChartFive.find(
    //       (chart) => chart.Name === this.chart_five
    //     );
    //     if (chartFive) {
    //       this.updateSelectedChartIds(chartFive.ID);
    //     }
    //   }

    //   let availableChartsForChartSix = availableChartsForChartFive
    //     .filter((chart) => {
    //       return !this.selectedChartIds.includes(chart.ID); // Exclude all selected charts
    //     })
    //     .map((chart) => {
    //       return { ID: chart.ID, Name: chart.Name };
    //     });

    //   // Update chartTypesName for chart_three dropdown
    //   this.chartTypesName = availableChartsForChartSix.map(
    //     (chart) => chart.Name
    //   );

    //   // Add ID of selected chart for chart_three
    //   if (this.chart_six) {
    //     const chartSix = availableChartsForChartSix.find(
    //       (chart) => chart.Name === this.chart_six
    //     );
    //     if (chartSix) {
    //       this.updateSelectedChartIds(chartSix.ID);
    //     }
    //   }

    //   if (selectedChart === "+ Create New Chart") {
    //     // If "Create New Chart" is selected, trigger createNewChart method
    //     this.openStudioDataDialog();
    //     if(this.chart_one) {
    //       this.chart_one = null;
    //     }
    //     if(this.chart_two) {
    //       this.chart_two = null;
    //     }
    //     if(this.chart_three) {
    //       this.chart_three = null;
    //     }
    //     if(this.chart_four) {
    //       this.chart_four = null;
    //     }
    //     if(this.chart_five) {
    //       this.chart_five = null;
    //     }
    //     if(this.chart_six) {
    //       this.chart_six = null;
    //     }
    //   }
    // },

    chartTypeChange(selectedChart) {
      // Reset selectedChartIds array
      this.selectedChartIds = [];

      // Add ID of selected chart for each chart dropdown
      const updateSelectedChart = (chart) => {
        const selected = this.chartTypes.find(c => c.Name === chart);
        if (selected) {
          this.updateSelectedChartIds(selected.ID);
        }
      };

      updateSelectedChart(this.chart_one);
      updateSelectedChart(this.chart_two);
      updateSelectedChart(this.chart_three);
      updateSelectedChart(this.chart_four);
      updateSelectedChart(this.chart_five);
      updateSelectedChart(this.chart_six);

      // Update chartTypesName to exclude selected charts but always include "+ Create New Chart"
      let availableCharts = this.chartTypes.filter(chart => !this.selectedChartIds.includes(chart.ID));
      this.chartTypesName = availableCharts.map(chart => chart.Name).concat("+ Create New Chart");

      if (selectedChart === "+ Create New Chart") {
        // If "Create New Chart" is selected, trigger createNewChart method
        this.openStudioDataDialog();
        if(selectedChart === this.chart_one) {
          this.chart_one = null;
        }
        if(selectedChart === this.chart_two) {
          this.chart_two = null;
        }
        if(selectedChart === this.chart_three) {
          this.chart_three = null;
        }
        if(selectedChart === this.chart_four) {
          this.chart_four = null;
        }
        if(selectedChart === this.chart_five) {
          this.chart_five = null;
        }
        if(selectedChart === this.chart_six) {
          this.chart_six = null;
        }
      }
    },

    openStudioDataDialog() {
      this.showStudioDataDialog = true;
    },

    closeModal() {
      this.showStudioDataDialog = false;
    },
    

    changeDashboard() {
      const selectedDashboard = this.dashboardsArr.find(
        (dashboard) => dashboard.name === this.dashboard
      );

      if (selectedDashboard) {
        // const dashboardName = selectedDashboard.name.replace(/\s/g, "-");
        // this.$router.push({
        //   name: "Board",
        //   params: { name: dashboardName },
        // });
        localStorage.setItem("dashboard", selectedDashboard.name);
        this.showInputs = false;
        this.showDashboard = true;
        if (this.showDashboard && this.$refs.childComponentRef) {
          this.$refs.childComponentRef.changeDashboard()
        }
      }
    },
    dashboardCreate() {
      this.showDashboard = false;
      this.showInputs = true;
      this.dashboard= null;
      this.chart_one = null;
      this.chart_two = null;
      this.chart_three = null;
      this.chart_four = null;
      this.chart_five = null;
      this.chart_six = null;
    },
    reloadDashboards() {
      this.getSavedDashboards();
      this.getSavedCharts();
      this.name = "";
      this.chart_one = null;
      this.chart_two = null;
      this.chart_three = null;
      this.chart_four = null;
      this.chart_five = null;
      this.chart_six = null;
      this.showDashboard = true;
      this.showInputs = false;
    },
     reloadAfterDelete() {
      this.getSavedDashboards();
      this.getSavedCharts();
      this.dashboard = null;
      this.showDashboard = false;
      this.showInputs = true;
    },
    nameUpdate() {
      const name_update = localStorage.getItem("dashboard");
      this.dashboard = name_update;
      this.getSavedDashboards();
    }
  },
  mounted() {
    this.getSavedDashboards();
    this.getSavedCharts();
    const defaultDashboard = localStorage.getItem("defaultDashboard");
    if (defaultDashboard) {
      this.showDashboard = true;
    }
    else {
      this.showInputs = true;
    }
  },
};
</script>
<template>
  <div class="left-menu-wrapper">
    <v-icon class="toggle-icon" :icon="isSidebarClosed ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"
      :style="toggleIconPosition" @click="toggleDrawer"></v-icon>

    <div :class="['sidebar', { close: isSidebarClosed }]">

      <div class="logo-div" v-if="!isSidebarClosed">
        <div class="img-wrap">
          <v-img aspect-ratio="16/9" :src="logo.logo" alt="logo"></v-img>
        </div>
      </div>

      <div class="logo-div2" v-if="isSidebarClosed">
        <div class="img-wrap2">
          <v-img :src="logo.logoIcon" alt="logo"></v-img>
        </div>
      </div>

      <ul class="nav-list">
        <!-- Loop through navigationItems -->
        <li v-for="(item, index) in navigationItems" :key="index" @click="toggleSubmenu(index)"
          :class="{ show: item.isVisible }">
          <div v-if="item.children" class="icon-link" :class="{ 'active-item': $route.path === item.route }">
            <router-link v-if="item.route" :to="item.route" class="link">
              <img :src="$route.path === item.route ? item.activeImage : item.image" />
              <span class="link-name">{{ item.title }}</span>
            </router-link>
            <div v-else class="link">
              <img :src="$route.path === item.route ? item.activeImage : item.image" />
              <span class="link-name">{{ item.title }}</span>
            </div>
            <div class="arrow-img arrow">
              <v-img src="../../../assets/Images/LeftMenu/rightarrowblack.png" alt="arrow down"></v-img>
            </div>
          </div>
          <ul v-if="item.children" :class="{ show: item.isVisible }" class="sub-menu">
            <li v-for="(childItem, childIndex) in item.children"
              :class="{ 'active-item': $route.path === childItem.route }" :key="childIndex">
              <router-link v-if="childItem.route" :to="childItem.route" class="sub-link">
                <!-- <div class="img-div"> -->
                <!-- <img :src="childItem.image" /> -->
                <img :src="$route.path === childItem.route ? childItem.activeImage : childItem.image" />
                <!-- </div> -->
                <span :class="{ 'active-text': $route.path === childItem.route }">{{ childItem.title }}</span>
              </router-link>
              <span v-else class="sub-link">
                <!-- <div class="img-div"> -->
                <img :src="childItem.image" />
                <!-- </div> -->
                <span :class="{ 'active-text': $route.path === childItem.route }">{{ childItem.title }}</span>
              </span>
            </li>
          </ul>
          <div class="icon-link" v-else :class="{ 'active-item': $route.path === item.route }">
            <router-link v-if="item.route" :to="item.route" class="link">
              <!-- <div class="img-div"> -->
              <img :src="$route.path === item.route ? item.activeImage : item.image" />
              <!-- </div> -->
              <span class="link-name" :class="{ 'active-text': $route.path === item.route }">{{ item.title }}</span>
            </router-link>
            <div v-else class="link">
              <!-- <div class="img-div"> -->
              <img :src="item.image" />
              <!-- </div> -->
              <div class="eda-content">
                <span class="link-name">{{ item.title }}</span>
                <div class="add-icon" v-if="item.title === 'exploratory data analysis'">
                  <v-img src="../../../assets/Images/LeftMenu/add.png" alt="plus icon" class="add-image"></v-img>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="left-footer" v-if="!isSidebarClosed">
        <div class="footer-img">
          <v-img aspect-ratio="16/9" :src="logo.tenantName" alt="logo"></v-img>
        </div>
        <span>{{ getSelectedTenant ? getSelectedTenant.name : selectedTenant }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  data: () => ({
    isVisible: false,
    isSidebarClosed: false,
    drawer: true,
    rail: false,
    miniVariant: false,
    screenWidth: 0,
    selectedTenant: localStorage.getItem("tenantName"),
    logo: {
      logo: require("../../../assets/Images/LeftMenu/logo.png"),
      logoIcon: require("../../../assets/Images/LeftMenu/logoicon.png"),
      tenantName: require("../../../assets/Images/LeftMenu/tenant-name.png"),
      clientLogo: require("../../../assets/Images/LeftMenu/Client Logo.png"),
    },
    navigationItems: [
      {
        title: "data management",
        route: "/assets",
        image: require("../../../assets/Images/LeftMenu/datamanagementblack.png"),
        activeImage: require("../../../assets/Images/LeftMenu/datamanagementwhite.png"),
      },
      {
        title: "data exploration",
        route: "/data-exploration",
        image: require("../../../assets/Images/LeftMenu/data-exploration-black.png"),
        activeImage: require("../../../assets/Images/LeftMenu/data-exploration-white.png"),
      },
      {
        title: "data QA-QC",
        route: "",
        image: require("../../../assets/Images/LeftMenu/data QA-QC.png"),
      },
      {
        title: "fluidsdata studio",
        route: "/Fluidsdata-studio",
        image: require("../../../assets/Images/LeftMenu/studio-black.png"),
        activeImage: require("../../../assets/Images/LeftMenu/fd-white.png"),
      },
      {
        title: "visualization & dashboards",
        route: "/Visualization-&-Dashboards",
        image: require("../../../assets/Images/LeftMenu/visualization & dashboardsblack.png"),
        activeImage: require("../../../assets/Images/LeftMenu/visualization & dashboardswhite.png"),
      },
      {
        title: "exploratory data analysis",
        image: require("../../../assets/Images/LeftMenu/eda-black.png"),
        activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        // children: [
        //   {
        //     title: "Data Inventory Analysis",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //     route: "/data-inventory-analysis"
        //   },
        //   {
        //     title: "correlation analysis",
        //     route: "/PVT-Properties",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //   },
        //   {
        //     title: "fluidsdata insights",
        //     route: "/Fluidsdata-insights",
        //     image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        //     activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        //   },
        // ],
      },
      {
        title: "Property Prediction",
        image: require("../../../assets/Images/LeftMenu/EDA black.png"),
        activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
        isVisible: false,
        children: [
          {
            title: "AI / ML Modeling",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
            route: "/ai-ml-modelling",
          },
          {
            title: "Advanced QA-QC",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
          },
          {
            title: "Dimensionality Reduction",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
          },
          {
            title: "Clustering",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
          },
          {
            title: "Supervised ML Model",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
          },
          {
            title: "Fluidsdata AI Model",
            image: require("../../../assets/Images/LeftMenu/EDA black.png"),
            activeImage: require("../../../assets/Images/LeftMenu/EDA white.png"),
            route: "/ai-ml-modelling/define-inputs/upload-reports/Fluidsdata-ai-model"
          },
        ],
      },
    ],
  }),
  watch: {
    // '$route.path'(newPath) {
    //   // Find the active item based on the new route path
    //   const activeItem = this.navigationItems.find(item => newPath === item.route);
    //   if (activeItem) {
    //     localStorage.setItem("activeMenu", activeItem.title);
    //   }
    // },
    '$route.path'(newPath) {
    let activeItem = null;
    let activeSubItem = null;

    // Check if the new route path matches any main item
    activeItem = this.navigationItems.find(item => newPath === item.route);

    // If no main item matches, check subitems
    if (!activeItem) {
      this.navigationItems.forEach(item => {
        if (item.children) {
          activeSubItem = item.children.find(subItem => newPath === subItem.route);
          if (activeSubItem) {
            activeItem = item;
            localStorage.setItem("activeMenu", activeSubItem.title); // Store subitem title
            localStorage.setItem("activeSubMenu", activeItem.title); // Optionally store parent item title
          }
        }
      });
    } else {
      localStorage.setItem("activeMenu", activeItem.title); // Store main item title
    }
  },
    screenWidth(newWidth) {
      if (newWidth < 768) {
        this.drawer = false;
        this.isSidebarClosed = true
      }
      else {
        this.drawer = true;
        this.isSidebarClosed = false
      }
    },
  },
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    toggleIconPosition() {
      return this.drawer ? "right: -12px;" : "left: 52px;";
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
      this.$emit("drawerStateChanged", this.drawer);
      this.isSidebarClosed = !this.isSidebarClosed;
    },
    toggleSubmenu(index) {
      // Loop through all items and close all submenus except the one being opened
      this.navigationItems.forEach((item, i) => {
        if (i === index) {
          // Toggle the clicked submenu
          item.isVisible = !item.isVisible;
        }
      });
    },
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 768) {
        this.drawer = false;
        this.isSidebarClosed = true
      }
      // else if (this.screenWidth > 768 && this.drawer == false) {
      //    this.drawer = false;
      //    this.isSidebarClosed = true
      //  }
      else {
        this.drawer = true;
        this.isSidebarClosed = false;
      }
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.toggleDrawer();
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenWidth);
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<template>
  <div class="studio-page-wrapper test-page" :style="rightContentStyle">
    <div class="top-wrap">
      <div class="text-img">
        <h2> Continous Volume Depletion </h2>
        <div v-if="receivedData">
          <div v-if="pdfLoader === true" class="test-pdf-div">
            <img :src="pdf_loader_image" alt="loader Image" width="20" height="20" />
          </div>
          <div v-else-if="receivedData.testID !== ''" @click="goToPdf()" class="test-pdf-div">
            <img :src="pdf_icon" alt="pdf icon" width="20" height="20" />
            <div class="pdf-text">Download PDF</div>
          </div>
        </div>
      </div>
      <div class="top-content" v-if="receivedData">
        <v-avatar size="56" rounded="0">
          <v-img src="../../../assets/Images/Assets/top image.png" alt="test image"></v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="6">
            <div>
              <p>Test ID</p>
              <p class="test-top-values">{{ receivedData.testID }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.sampleID">
            <div>
              <p>Sample ID</p>
              <p class="test-top-values">{{ receivedData.sampleID }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.saturationPressure_UOM && receivedData.saturationPressure">
            <div>
              <p>Saturation Pressure</p>
              <p class="test-top-values">{{
                receivedData.saturationPressure }}
                <span> ({{
                  receivedData.saturationPressure_UOM }})
                </span>
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.testTemperature && receivedData.testTemperature_UOM">
            <div>
              <p>Test Temperature</p>
              <p class="test-top-values">{{
                receivedData.testTemperature }}
                <span> ({{
                  receivedData.testTemperature_UOM }})
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="top-content" v-else>
        <test-loaders />
      </div>
    </div>
    <div ref="scrollable" class="tabs-data" tabindex="0" @keydown="handleKeyDown">
      <v-tabs v-model="tab">
        <v-tab value="one">Cvd Test Properties</v-tab>
        <v-tab value="two">Produced Gas Compositions</v-tab>
        <v-tab value="three">Last Stage Liquid Compositions</v-tab>
      </v-tabs>

      <v-card-text class="pa-0 pt-4">
        <v-window v-model="tab">
          <v-window-item value="one">
            <CvdTestProperties @data-updated="handleDataUpdate" />
          </v-window-item>

          <v-window-item value="two">
            <ProducedGasCompositions />
          </v-window-item>

          <v-window-item value="three">
            <LastStageLiquidCompositions />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
    <Filters @drawerStateChanged="handleRightDrawerStateChange" />
  </div>
</template>

<script>
import CvdTestProperties from "./CvdTestProperties.vue";
import CvdGasProperties from "./CvdGasProperties.vue";
import TestLoaders from '../Loaders/TestLoaders.vue';
import ProducedGasCompositions from './ProducedGasCompositions.vue';
import LastStageLiquidCompositions from './LastStageLiquidCompositions.vue';
import Filters from "../../../components/layout/Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import axios from "axios";
export default {
  components: { CvdTestProperties, CvdGasProperties, TestLoaders, ProducedGasCompositions, LastStageLiquidCompositions, Filters },
  name: "CvdTabs",
  data: () => ({
    tab: null,
    receivedData: null,
    assetName: "",
    fieldName: "",
    wellName: "",
    pdf_icon: require("../../../assets/Images/pdfIcon.png"),
    pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
    pdfLoader: false,
    snackbarMessage: null,
  }),
  mixins: [screenWidthMixin],
  methods: {
    handleDataUpdate(data) {
      this.receivedData = data;
    },
    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.snackbarMessage = "PDF data is not available."
            this.pdfLoader = false;
          }
        })
        .finally(() => {
          this.pdfLoader = false;
        });
    },
    handleKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowDown') {
        // Handle scrolling down
        this.$refs.scrollable.scrollTop += 50; // You can adjust the scrolling speed as needed
      } else if (key === 'ArrowLeft') {
        // Handle switching to the previous tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 0 ? 'three' : ['one', 'two', 'three'][currentIndex - 1];
      } else if (key === 'ArrowRight') {
        // Handle switching to the next tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 2 ? 'one' : ['one', 'two', 'three'][currentIndex + 1];
      }
    },
  },
  mounted() {
    this.tab = 'one';
    this.assetName = this.$route.params.asset.replace(/-/g, ' ');
    this.fieldName = this.$route.params.field.replace(/-/g, ' ');
    this.wellName = this.$route.params.well;
    this.$refs.scrollable.focus();
  },
};
</script>
<template>
  <div class="studio-page-wrapper test-page" :style="rightContentStyle">
    <div class="top-wrap">
      <div class="text-img">
        <h2> Constant Composition Expansion </h2>
        <div>
          <div v-if="pdfLoader === true" class="test-pdf-div">
            <img :src="pdf_loader_image" alt="loader Image" width="20" height="20" />
          </div>
          <div v-else @click="goToPdf()" class="test-pdf-div">
            <img :src="pdf_icon" alt="pdf icon" width="20" height="20" />
            <div class="pdf-text">Download PDF</div>
          </div>
        </div>
      </div>
      <div class="top-content" v-if="showLoaders == true">
        <test-loaders />
      </div>
      <div class="top-content" v-else>
        <v-avatar size="56" rounded="0">
          <v-img src="../../../assets/Images/Assets/top image.png" alt="test image"></v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col cols="12" lg="6" class="pb-2">
            <div>
              <p class="test-top-heading">Test ID</p>
              <p class="test-top-values">{{ testID }}</p>
            </div>
          </v-col>
          <v-col cols="12" lg="6" class="pb-2" v-if="sampleID">
            <div>
              <p class="test-top-heading">Sample ID</p>
              <p class="test-top-values">{{ sampleID }}</p>
            </div>
          </v-col>
          <v-col cols="12" lg="6" class="pb-2" v-if="saturationPressure_UOM && saturationPressure">
            <div>
              <p class="test-top-heading">Saturation Pressure</p>
              <p class="test-top-values">{{
                saturationPressure }}
                <span> ({{
                  saturationPressure_UOM }})
                </span>
              </p>
            </div>
          </v-col>
          <v-col cols="12" lg="6" v-if="testTemperature && testTemperature_UOM">
            <div>
              <p class="test-top-heading">Test Temperature</p>
              <p class="test-top-values">{{
                testTemperature }}
                <span> ({{
                  testTemperature_UOM }})
                </span>
              </p>
            </div>
          </v-col>
        </v-row>

      </div>
    </div>
    <chartLoaders v-if="showLoaders == true"></chartLoaders>
    <screen-loader v-if="showLoaders == true"></screen-loader>
    <div v-else-if="timeoutMessage != ''" class="tabs-no-test-div timeout">
      <div class="text-center">
        {{ timeoutMessage }}
        <v-icon flat @click="loadItems()" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
      </div>
    </div>
    <v-card-text class="tabs-data chart-table-wrap2" v-else-if="serverItems.length > 0 && showLoaders == false">
      <!-- <v-select
        v-model="selectedSort"
        label="Sort Step Pressure"
        :items="sortOptions"
        @update:modelValue="onSelectSort"
      >
      </v-select> -->
      <div class="chart-wrap" v-if="hasStepPressure">
        <div v-for="(variable, index) in variables" :key="index" class="chart-column">
          <div :ref="`chart-${index}`" class="main-chart">
            <div v-if="serverItems.length > 0">
              <v-btn @click="openModal(index)" flat size="small" class="chart-preview-btn2 text-none">
                Expand
              </v-btn>
            </div>
          </div>

          <v-dialog v-model="isPreviewOpen[index]" width="100vw" class="multi-chart-preview-div">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal(index)" flat icon class="chart-preview-close-btn" size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="`modal-chart-${index}`" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="!hasStepPressure" class="text-center pt-5 pb-1">
        <h4>Charts couldn't be plotted as step pressure is not available in this test.</h4>
      </div>
      <div class="table-wrap">
        <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Constant Composition Expansion</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <img class="pointer" :src="documentDownload" alt="" />
            <div>
              Download CSV
            </div>
          </div>
        </div>
        <v-data-table :headers="headers" :items="serverItems" :total-items="totalItems" :items-per-page="itemsPerPage"
          :loading="loading" :search="search" :item-value="name" virtual-items fixed-header height="calc(100vh - 335px)">
          <template v-slot:headers>
            <tr class="main-header">
              <th v-for="(header, index) in headers" :key="index" class="sticky-header">
                {{ header.name }}
                <span @click="handleHeaderClick(header.key)">
                  <span v-if="header.key === 'stepPressure'">
                    <v-icon v-if="sortOrder === 'asc'" @click.stop="sortStepPressure('desc')"
                      small>mdi-arrow-down</v-icon>
                    <v-icon v-else @click.stop="sortStepPressure('asc')" small>mdi-arrow-up</v-icon>
                  </span>
                </span>
              </th>
            </tr>
            <tr class="sub-header">
              <th v-for="(header, index) in headers" :key="index">
                {{ header.unit }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(header, index) in headers" :key="index">
                {{ displayValue(header.key, item[header.key]) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div v-else-if="(!serverItems || serverItems.length === 0) && showLoaders == false" class="no-test-div">
      <h4>No data available.</h4>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
    <Filters @drawerStateChanged="handleRightDrawerStateChange" />
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ChartLoaders from "../Loaders/ChartLoaders.vue";
import axios from "axios";
import TestLoaders from "../Loaders/TestLoaders.vue";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import { getDisplayName } from '../../../utils/cache';
import api from "../../../axiosInterceptor";
import Filters from "../../../components/layout/Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";

export default {
  name: "CceData",
  components: {
    ChartLoaders,
    TestLoaders,
    ScreenLoader,
    Filters,
  },
  data: () => ({
    showLoaders: true,
    itemsPerPage: 0,
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    variables: ["1", "2"],
    isPreviewOpen: Array().fill(false),
    chartData: Array().fill(null),
    cachedData: {},
    testID: "",
    testName: "",
    sampleID: "",
    hasStepPressure: false,
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    assetName: "",
    fieldName: "",
    wellName: "",
    documentDownload: require("../../../assets/Images/csv download.png"),
    pdf_icon: require("../../../assets/Images/pdfIcon.png"),
    pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
    pdfLoader: false,
    snackbarMessage: null,
    // selectedSort: "High to Low",
    // sortOptions: ["High to Low", "Low to High"],
    sortOrder: 'desc',
    timeoutMessage: "",
  }),
  mixins: [screenWidthMixin],
  computed: {
    hasXAxisVariable() {
      return this.serverItems.some(item => item.hasOwnProperty('stepPressure') || this.variables.some(variable => item.hasOwnProperty(variable)));
    }
  },
  methods: {
    // ...mapActions("metaData", ["getDisplayName"]),
    // onSelectSort() {
    //   const sortOrder = this.selectedSort === "High to Low" ? 0 : 1; // Set sort order based on selection

    //   this.loadItems({ sortOrder })
    //     .then((ccetest) => {
    //       this.variables.forEach((variable, index) => {
    //         this.plotChartForVariable(variable, index, ccetest);
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error loading data:", error);
    //     });
    // },
    sortStepPressure(order) {
      this.sortOrder = order;
      if (order === 'asc') {
        this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
      } else {
        this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
      }
    },
    downloadChartCSV(index) {
      const chartData = this.chartData[index];

      // Check if chart data exists
      if (chartData) {
        const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;

        // Prepare CSV header dynamically
        const csvHeader = [
          chartData.layout.xaxis.title.text,
          chartData.layout.yaxis.title.text,
        ];

        // Prepare CSV rows
        const csvRows = chartData.trace.x.map((xValue, i) => [
          xValue,
          chartData.trace.y[i],
        ]);

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      }
    },

    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var cceId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;
      this.timeoutMessage = "";

      try {
        let response = await api.get(
          `public/api/v1/samples/${sampleId}/ccetests/${cceId}`
        );

        this.serverItems =
          response.data.data.ccetest && response.data.data.ccetest.CCETestStep
            ? response.data.data.ccetest.CCETestStep
            : [];
        this.totalItems =
          response.data.data.ccetest && response.data.data.ccetest.length
            ? response.data.data.ccetest.length
            : 0;
        this.testID = response.data.data.ccetest.ID;
        this.testName = response.data.data.ccetest.name;
        this.sampleID = response.data.data.ccetest.sampleID;
        this.saturationPressure = response.data.data.ccetest.saturationPressure;
        this.saturationPressure_UOM =
          response.data.data.ccetest.saturationPressure_UOM;
        this.testTemperature = response.data.data.ccetest.testTemperature;
        this.testTemperature_UOM =
          response.data.data.ccetest.testTemperature_UOM;

        //   if (this.selectedSort === "High to Low") {
        //   this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
        // } else {
        //   this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
        // }

        const sortByKeys = response.data.data.ccetest.sortBy.map(key =>
          key.charAt(0).toLowerCase() + key.slice(1)
        );
        // Check if serverItems contains stepPressure
        this.hasStepPressure = this.serverItems.some(item => item.hasOwnProperty('stepPressure'));

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );

        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        // Update variables array with extracted keys, excluding stepPressure
        this.variables = Array.from(allKeys).filter(
          (key) => key !== "stepPressure"
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(allUniqueKeys.map(async (key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          const { displayName, digits } = await getDisplayName('ConstantCompositionExpansionTest', capitalizedKey);
          displayNames.push(displayName);
          digitsArray.push(digits);
        }));

        // Update the headers array to use display names
        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index]; // Use display name instead of key
          let unit = uom ? uom[key + "_UOM"] : '-';
          // Check if unit is empty or equals "data-not-available", then set it to "-"
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits, // Add digits to headers
          };
        });

        return response.data.data.ccetest;
      } catch (error) {
        this.timeoutMessage = error;
        // console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const sampleId = this.sampleID;
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": this.tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.snackbarMessage = "PDF data is not available."
            this.pdfLoader = false;
          }
        })
        .finally(() => {
          this.pdfLoader = false;
        });
    },

    async plotChartForVariable(variable, chartIndex, ccetest) {
      if (!ccetest || !ccetest.CCETestStep) {
        return;
      }

      // const testTemperature = ccetest.testTemperature;
      const stepPressures = ccetest.CCETestStep.map(
        (step) => step.stepPressure
      );

      const trace = {
        x: stepPressures,
        y: ccetest.CCETestStep.map((step) => step[variable]),
        type: "scatter",
        mode: "lines+markers",
        name: variable,
      };

      const layout = {
        title: {
          text: `${this.getHeaderDisplayName(variable)} vs Pressure - CCE`,
          font: {
            size: 12,
          },
        },
        xaxis: { title: "Pressure (psi)" },
        yaxis: {
          title: this.getHeaderDisplayName(variable)
        },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
        modeBarButtonsToAdd: [
          {
            name: "Download CSV",
            icon: {
              path: "M12 15.5l-5-5h3v-6h4v6h3l-5 5zm-10 3h20v2h-20z",
            },
            click: this.handleDownloadCSV.bind(this, chartIndex),
          },
        ],
      };
      this.chartData[chartIndex] = {
        trace: trace,
        layout: layout,
        config: config,
      };

      const chartRef = this.$refs["chart-" + chartIndex];
      if (chartRef && chartRef.length > 0) {
        const chartElement = chartRef[0];
        Plotly.newPlot(chartElement, [trace], layout, config);
      } else {
        return
      }
    },

    getHeaderDisplayName(variable) {
      const header = this.headers.find(header => header.key === variable);
      if (header) {
        return header.unit !== '-' ? `${header.name} (${header.unit})` : header.name;
      }
      return variable;
    },

    displayValue(key, value) {
      const header = this.headers.find(header => header.key === key);
      const digits = header ? header.digits : 0; // Get digits from header

      if (value !== null && value !== undefined) {
        // Format the value with the specified number of decimal places
        return digits > 0 ? value.toFixed(digits) : value;
      } else {
        return "-";
      }
    },

    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `constant_composition_expansion_table.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },

    handleDownloadCSV(chartIndex) {
      this.downloadChartCSV(chartIndex);
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.plotModalChart(index);
      });
    },
    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    plotModalChart(index) {
      const chartData = this.chartData[index];
      if (chartData) {
        const modalChartRef = this.$refs[`modal-chart-${index}`][0];
        Plotly.newPlot(
          modalChartRef,
          [chartData.trace],
          chartData.layout,
          chartData.config
        );
      }
    },
  },
  watch: {
    $route(to, from) {
      // Call the loadItems method when route parameters change
      this.loadItems()
        .then((ccetest) => {
          this.variables.forEach((variable, index) => {
            this.plotChartForVariable(variable, index, ccetest);
          });
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });
    },
  },
  mounted() {
    this.assetName = this.$route.params.asset.replace(/-/g, " ");
    this.fieldName = this.$route.params.field.replace(/-/g, " ");
    this.wellName = this.$route.params.well;
    this.loadItems().then((ccetest) => {
      this.variables.forEach((variable, index) => {
        this.plotChartForVariable(variable, index, ccetest);
      });
    });
  },
};
</script>
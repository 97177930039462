<template>
  <div class="filter-wrapper">
    <v-form>
      <TabularLoaders v-if="showLoaders === true" class="pr-4" />
      <v-row no-gutters v-else>
        <!-- <v-col cols="12" class="pa-0 pb-3 save-update-chart-btns" v-if="createNewChart">
          <v-btn prepend-icon="mdi-plus" class="save-btn text-none" flat @click="goToCreateChart()">Chart</v-btn>
        </v-col> -->
        <v-col cols="12" class="studio-input-form pr-4" v-if="isFluidsDataStudio">
          <h6 class="select-chart-heading">Select Saved Chart</h6>
          <v-row no-gutters items-center>
            <v-col cols="12" md="6" lg="5" class="pa-0 pr-md-3" v-if="shouldRenderChartList">
              <v-select v-model="selectedSavedChartName" :items="savedChartsNames" placeholder="Select Saved Chart"
                @update:modelValue="savedChartChange">
                <!-- Show loading indicator while loading -->
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingSavedCharts">
                    <v-progress-circular indeterminate color="black" class="loaders"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingSavedCharts">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="7" class="pa-0 d-flex align-center justify-end">
              <div class="saved-chart-right-btns">
                <v-btn v-if="selectedSavedChartName != null" flat class="reset-btn text-none" @click="resetChartData">
                  <!-- <v-avatar size="20" class="mr-2" rounded="0">
                    <v-img src="../../../assets/Images/reset.png" alt="profile"></v-img>
                  </v-avatar> -->
                  New Chart
                </v-btn>
                <v-btn v-if="selectedSavedChartName != null && accessLevel == 'private'" :loading="deleteLoading" flat
                  class="delete-btn ml-2 text-none" @click="deleteChart">
                  <v-avatar size="20" class="mr-2" rounded="0">
                    <v-img src="../../../assets/Images/delete btn.png" alt="profile"></v-img>
                  </v-avatar>
                  Delete</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12 pr-4">
          <div class="chart-opts">
            <h6>Chart Options</h6>
            <img @click="toggleInputs" v-if="isFluidsDataStudio && !showInputs" :src="icon" alt="chevron-down">
            <img @click="toggleInputs" v-if="isFluidsDataStudio && showInputs" :src="icon2" alt="chevron-up">
          </div>
        </v-col>
        <div class="chart-opts-input" v-if="showInputs">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4 pb-5">
              <label for="">Enter Chart Title</label>
              <v-text-field v-model="chartTitle" required placeholder="Enter Chart Title" hide-details
                outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4">
              <label for="">Select Table Type</label>
              <v-select v-model="selectedTableTypeName" :items="tableTypesNames" placeholder="Select Table Type"
                @update:modelValue="tableTypeChange">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingTableType">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingTableType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4">
              <label for="">Select Chart Type</label>
              <v-select v-model="selectedChartTypeName" :items="chartTypesNames" placeholder="Select Chart Type"
                @update:modelValue="chartTypeChange">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingChartType">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingChartType">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4" v-if="showXAxis">
              <label for="">Select Coulmn for X-Axis</label>
              <v-select v-model="selectedXAxisKey" :items="xAxisItems" item-title="displayName" item-value="name"
                placeholder="Select Column for X-Axis" @update:modelValue="handleXAxisSelection">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingXAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingXAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4" v-if="showYAxis">
              <label for="">Select Column(s) for Y-Axis</label>
              <v-select v-model="selectedYAxisKey" :items="yAxisItems" multiple chips clearable closable-chips
                placeholder="Select Column for Y-Axis" item-title="displayName" item-value="name" :return-object="false"
                @update:modelValue="handleYAxisSelection" class="custom-y-axis-chips">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingYAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingYAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4" v-if="showZAxis">
              <label for="">Select Column for Z-Axis</label>
              <v-select v-model="selectedZAxisKey" placeholder="Select Column for Z-Axis" :items="zAxisItems"
                item-title="displayName" item-value="name" @update:modelValue="handleZAxisSelection">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4" v-if="showColorGroup">
              <label for="">Select Column for Color Grouping</label>
              <v-select v-model="selectedColorGroupKey" clearable placeholder="Select Column for Color Grouping"
                :items="filteredColorGroupItems" item-title="displayName" item-value="name"
                @update:modelValue="handleGroupSelection">
                <template #prepend-item>
                  <div class="loader-container" v-if="loadingZAxisData">
                    <v-progress-circular indeterminate color="black"></v-progress-circular>
                  </div>
                </template>
                <template #no-data>
                  <v-list-item v-if="!loadingZAxisData">
                    <v-list-item-content>No data available</v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col v-if="showXBinsInput" cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4">
              <label for="">Number of X bins</label>
              <div id="rangeValue">{{ xBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="xBins"
                @input="updateXBins" />
            </v-col>
            <v-col v-if="showYBinsInput" cols="12" md="6" lg="4" xl="3" class="pa-0 pr-4">
              <label for="">Number of Y bins</label>
              <div id="rangeValue">{{ yBinsAsNumber }}</div>
              <input type="range" id="rangeInput" class="slider" min="2" max="100" step="1" v-model="yBins"
                @input="updateYBins" />
            </v-col>
          </v-row>
        </div>
        <v-col v-if="$route.name === 'DataInventoryAnalysis'" cols="12" class="pa-0">
          <label for="">Select Saved Dashboard</label>
          <v-select v-model="dashboard" placeholder="Select Saved Dashboard" :items="dashboardItems"
            @update:modelValue="handleDshboardSelection"></v-select>
        </v-col>
      </v-row>
      <div class="studio-btns pr-4" v-if="showInputs">
        <div>
          <v-btn v-if="selectedSavedChartName == null && createNewChart == false" flat :disabled="!isFormValid"
            :loading="createLoading" class="save-btn ml-1 text-none" @click="createChart">
            <v-avatar size="20" class="mr-2" rounded="0">
              <v-img src="../../../assets/Images/save btn.png" alt="profile"></v-img>
            </v-avatar>
            <span>Save</span>
          </v-btn>
          <v-btn v-if="selectedSavedChartName != null && accessLevel == 'private'" flat :disabled="!isFormValid"
            :loading="updateLoading" class="save-btn ml-1 text-none" @click="updateChart">
            <v-avatar size="20" class="mr-2" rounded="0">
              <v-img src="../../../assets/Images/save btn.png" alt="profile"></v-img>
            </v-avatar>
            Update
          </v-btn>
        </div>
      </div>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout">
        {{ snackbar.message }}
      </v-snackbar>
    </v-form>
    <StudioModal ref="childComponent" @open-dialog="openDialog" />
    <Filters v-if="isFluidsDataStudio" @drawerStateChanged="handleRightDrawerStateChange"
      :callDefaultFilter="callDefaultFilter" :selectedType="selectedTableTypeName"
      @filter-data="getTableDataAgainstFilter" @reset-saved-filter="handleResetSavedFilter"
      @reset-call-default-filter="resetCallDefaultFilter" />
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import TabularLoaders from "../Loaders/TabularLoaders.vue";
import Filters from "../Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import StudioModal from "./StudioModal.vue";
export default {
  name: "StudioDataLeft",
  components: { TabularLoaders, Filters, StudioModal },
  data() {
    return {
      chartTypes: [],
      tableTypes: [],
      chartTitle: "",
      chartType: this.selectedChartType,
      xAxisItems: [],
      yAxisItems: [],
      zAxisItems: [],
      colorGroupItems: [],
      selectedXAxisKey: [],
      selectedYAxisKey: [],
      selectedZAxisKey: [],
      selectedColorGroupKey: null,
      xAxisData: [],
      yAxisData: [],
      zAxisData: [],
      colorGroupData: [],
      showXBinsInput: false,
      showXAxis: true,
      showYAxis: true,
      showZAxis: false,
      showColorGroup: false,
      xBins: 10,
      showYBinsInput: false,
      yBins: 10,
      chartTypesNames: [],
      tableTypesNames: [],
      selectedChartTypeName: null,
      selectedTableTypeName: null,
      selectedSavedChartName: null,
      savedCharts: [],
      savedChartsNames: [],
      tableData: [],
      showLoaders: false,
      deleteError: null,
      showTooltip: false,
      snackbar: {
        visible: false,
        message: "",
        color: "success",
        timeout: 3000,
      },
      createLoading: false,
      updateLoading: false,
      deleteLoading: false,
      loadingSavedCharts: false,
      loadingXAxisData: false,
      loadingYAxisData: false,
      loadingZAxisData: false,
      loadingTableType: false,
      loadingChartType: false,
      createNewChart: false,
      chartSaved: false,
      accessLevel: "",
      resetSavedFilter: false,
      callDefaultFilter: false,
      icon: require("../../../assets/Images//arrow-down.png"),
      icon2: require("../../../assets/Images/arrow-up.png"),
      showInputs: false,
      // timeoutMessage: "",
      // tableDataType: "",
      localArray: [
        'Asset',
        'Field',
        'Well',
        'Reservoir',
        'Sample UID',
        'Component',
        'Saturation Pressure',
        'Saturation Temperature',
        'Step Temperature',
        'Test Temperature',
      ],
    };
  },
  props: {
    selectedChartType: {
      type: String,
    },
  },
  mixins: [screenWidthMixin],
  computed: {
    filteredColorGroupItems() {
      const displayNames = this.colorGroupItems.map(item => item.displayName);
      const filtered = displayNames.filter(displayName => this.localArray.includes(displayName));
      return filtered;
    },
    isFluidsDataStudio() {
      return this.$route.path === '/Fluidsdata-studio';
    },
    updateXBins() {
      this.$emit("update-x-bins", Number(this.xBins));
    },
    updateYBins() {
      this.$emit("update-y-bins", Number(this.yBins));
    },
    xBinsAsNumber() {
      return Number(this.xBins);
    },
    yBinsAsNumber() {
      return Number(this.yBins);
    },
    isFormValid() {
      const isBasicFieldsFilled =
        this.selectedChartTypeName &&
        this.chartTitle &&
        this.selectedTableTypeName;
      const isXAxisFilled = !this.showXAxis || this.selectedXAxisKey;
      const isYAxisFilled =
        !this.showYAxis ||
        (Array.isArray(this.selectedYAxisKey) &&
          this.selectedYAxisKey.length > 0);
      const isZAxisFilled = !this.showZAxis || this.selectedZAxisKey;

      return (
        isBasicFieldsFilled && isXAxisFilled && isYAxisFilled && isZAxisFilled
      );
    },
    shouldRenderChartList() {
      return this.shouldRenderComponent([
        "FluidsDataStudio"
      ]);
    },
  },
  watch: {
    selectedColorGroupKey(newValue) {
      if (newValue === "") {
        this.selectedColorGroupKey = null;
      }
    },
    rightContentStyle(newStyle) {
      this.$emit("update-right-content-style", newStyle);
    }
  },
  methods: {
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
  //   retryGetTableData() {
  //   let selectedType = this.tableDataType;
  //   this.getTableData(selectedType);
  // },
    toggleInputs() {
      this.showInputs = !this.showInputs
    },
    resetCallDefaultFilter() {
      this.callDefaultFilter = false;
    },
    handleResetSavedFilter() {
      const savedChartData = localStorage.getItem("savedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData) {
          this.savedChartChangeLoad(chartData);
        }
      }
    },
    shouldRenderComponent(allowedRoutes) {
      return allowedRoutes.includes(this.$route.name);
    },
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },

    goToCreateChart() {
      this.resetChartData();
    },

    async tableTypeChange(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.resetAxisSelections();
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);

      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );

        if (response.data.statusCode === 200) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(column => column.columnDisplayName);
          this.xAxisItems = columns.map(column => ({ displayName: column.columnDisplayName, name: column.columnName }));
          this.yAxisItems = columns.map(column => ({ displayName: column.columnDisplayName, name: column.columnName }));
          this.zAxisItems = columns.map(column => ({ displayName: column.columnDisplayName, name: column.columnName }));
          this.colorGroupItems = columns.map(column => ({ displayName: column.columnDisplayName, name: column.columnName }));
        }
      } catch (error) {
        console.log("Error", error);
      }

      await this.getTableData(selectedType);
    },

    async tableTypeChangeAgainstFilter(selectedType) {
      this.loadingXAxisData = true;
      this.loadingYAxisData = true;
      this.loadingZAxisData = true;
      this.resetAxisSelections();
      this.selectedTableTypeName = selectedType;
      const tableTypeString = JSON.stringify(selectedType);

      try {
        const response = await api.get(
          "public/api/v1/tables/type?table=" +
          encodeURIComponent(tableTypeString)
        );

        if (response.data.statusCode === 200) {
          this.loadingXAxisData = false;
          this.loadingYAxisData = false;
          this.loadingZAxisData = false;
          const columns = response.data.data.tabletypes[0].columns.filter(
            (column) => column.columnDisplayName
          );
          this.xAxisItems = columns.map((column) => ({
            displayName: column.columnDisplayName,
            name: column.columnName,
          }));
          this.yAxisItems = columns.map((column) => ({
            displayName: column.columnDisplayName,
            name: column.columnName,
          }));
          this.zAxisItems = columns.map((column) => ({
            displayName: column.columnDisplayName,
            name: column.columnName,
          }));
          this.colorGroupItems = columns.map(column => ({ displayName: column.columnDisplayName, name: column.columnName }));
        }
      } catch (error) {
        console.log("Error", error);
      }
    },

    async getTableData(selectedType) {
      // this.tableDataType = selectedType;
      // this.timeoutMessage = "";
      try {
        const response = await api.get(
          `public/api/v1/tables/data/${selectedType}`
        );

        if (response.data.statusCode === 200) {
          this.tableData = response.data.data.TableData.rows;
        }
      } catch (error) {
        console.log("Error", error);
        // this.timeoutMessage = error
      }
    },

    async getTableDataAgainstFilter({ filterData, type }) {
      this.$emit("sample-error", "");
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);

      try {
        const queryParams = {};

        const minMaxFormatter = ({ min = "", max = "" }) => JSON.stringify({ min, max });

        // Add filters to queryParams only if they have values
        if (filterData.asset && filterData.asset.length) queryParams.asset = JSON.stringify(filterData.asset);
        if (filterData.field && filterData.field.length) queryParams.field = JSON.stringify(filterData.field);
        if (filterData.well && filterData.well.length) queryParams.well = JSON.stringify(filterData.well);
        if (filterData.reservoir && filterData.reservoir.length) queryParams.reservoir = JSON.stringify(filterData.reservoir);
        if (filterData.sampleKind && filterData.sampleKind.length) queryParams.sampleKind = JSON.stringify(filterData.sampleKind);
        if (filterData.fluidSampleID && filterData.fluidSampleID.length) queryParams.fluidSampleID = JSON.stringify(filterData.fluidSampleID);
        if (filterData.fluidSampleContainerID && filterData.fluidSampleContainerID.length) queryParams.fluidSampleContainerID = JSON.stringify(filterData.fluidSampleContainerID);
        if (filterData.source) queryParams.source = `"${filterData.source}"`;
        if (filterData.sampleDate) queryParams.sampleDate = minMaxFormatter(filterData.sampleDate);
        if (filterData.depth) queryParams.depth = minMaxFormatter(filterData.depth);
        if (filterData.formationPressure) queryParams.formationPressure = minMaxFormatter(filterData.formationPressure);
        if (filterData.formationTemperature) queryParams.formationTemperature = minMaxFormatter(filterData.formationTemperature);

        const queryString = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");

        const response = await api.get(`public/api/v1/tables/data/${type}?${queryString}`);

        if (response && response.data && response.data.statusCode === 200) {
          this.tableData = response.data.data.TableData.rows;

          const savedChartData = localStorage.getItem("savedChartData");
          if (savedChartData) {
            const chartData = JSON.parse(savedChartData);
            if (chartData) {
              this.tableTypeChangeAgainstFilter(chartData.TableType);
              this.$nextTick(() => {
                this.populateChartData(chartData);
              });
            }
          }
        } else {
          // Handle unexpected response structure
          this.$emit("sample-error", "Unexpected response structure.");
        }
      } catch (error) {
        if (error.response && error.response.data) {
          let sampleError = error.response.data.error;
          this.$emit("sample-error", sampleError);
        } else {
          this.$emit("sample-error", "An error occurred while fetching data.");
        }
      } finally {
        this.showLoaders = false;
        var showLoaders = this.showLoaders;
        this.$emit("loading-state", showLoaders);
      }
    },

    chartTypeChange(selectedType) {
      this.chartType = selectedType;
      const selectedChart = this.chartTypes.find(
        (chart) => chart.Name === selectedType
      );
      if (selectedChart) {
        this.showXBinsInput = selectedChart.has_x_bins;
        this.showYBinsInput = selectedChart.has_y_bins;
        this.selectedChartTypeName = selectedChart.Name;
        this.showXAxis = selectedChart.has_x;
        this.showYAxis = selectedChart.has_y;
        if (this.showYAxis == false) {
          this.selectedYAxisKey = [];
          this.handleYAxisSelection();
        }
        this.showZAxis = selectedChart.has_z;
        this.showColorGroup = selectedChart.has_group
        if (this.showColorGroup == false) {
          this.selectedColorGroupKey = null;
          this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
          this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
          this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        }
        this.$emit("chart-type-changed", selectedType);
      } else {
        console.error("Selected chart not found.");
      }
    },

    async savedChartChange() {
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      let defaultFilterID = localStorage.getItem("defaultFilter");
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (selectedChart) {
        const chartId = selectedChart.ID;

        try {
          const response = await api.get(`public/api/v1/charts/${chartId}`);

          if (response.data.statusCode === 200) {
            let selectedChartData = response.data.data.chart;
            this.accessLevel = selectedChartData.AccessLevel
            await this.tableTypeChange(selectedChartData.TableType);
            localStorage.setItem("savedChartData", JSON.stringify(selectedChartData));

            if (selectedChart && defaultFilterID) {
              this.callDefaultFilter = true;
              return;
            }
            this.showLoaders = false;
            var showLoaders = this.showLoaders;
            this.$emit("loading-state", showLoaders);
            this.$nextTick(() => {
              this.populateChartData(selectedChartData);
            });
            this.createNewChart = true;
          }
        } catch (error) {
          console.log("Error", error);
        }
      } else {
        console.error("Selected chart not found.");
      }
    },

    async savedChartChangeLoad(chartData) {
      this.$emit("sample-error", "");
      this.showLoaders = true;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      this.selectedSavedChartName = chartData.Name;
      let selectedChartData = chartData;
      this.accessLevel = selectedChartData.AccessLevel
      let defaultFilterID = localStorage.getItem("defaultFilter");

      await this.tableTypeChange(selectedChartData.TableType);
      if (defaultFilterID) {
        return;
      }
      this.showLoaders = false;
      var showLoaders = this.showLoaders;
      this.$emit("loading-state", showLoaders);
      this.$nextTick(() => {
        this.populateChartData(selectedChartData);
      });
      this.createNewChart = true;
    },

    populateChartData(chartData) {
      this.chartTitle = chartData.Name;
      var chartTitle = this.chartTitle;
      this.$emit("chart-title", chartTitle);
      this.selectedChartTypeName = chartData.ChartType;
      this.selectedTableTypeName = chartData.TableType;
      this.selectedColorGroupKey = chartData.GroupColumn;

      const selectedXAxisItem = this.xAxisItems.find(
        (item) => item.name === chartData.XAxisColumn
      );
      this.selectedXAxisKey = selectedXAxisItem ? selectedXAxisItem.name : null;

      if (typeof chartData.YAxisColumn === "string") {
        const yAxisColumnArray = chartData.YAxisColumn.split(",");
        if (this.selectedColorGroupKey && yAxisColumnArray.length > 1) {
          yAxisColumnArray.pop(); // Remove the last element if selectedColorGroupKey is present
        }
        this.selectedYAxisKey = yAxisColumnArray.map((yAxisColumnName) => {
          const selectedYAxisItem = this.yAxisItems.find(
            (item) => item.name === yAxisColumnName
          );
          return selectedYAxisItem ? selectedYAxisItem.name : null;
        });
      } else {
        this.selectedYAxisKey = [];
      }

      const selectedZAxisItem = this.zAxisItems.find(
        (item) => item.name === chartData.ZAxisColumn
      );
      this.selectedZAxisKey = selectedZAxisItem ? selectedZAxisItem.name : null;

      this.xBins = chartData.NumXBins;
      this.yBins = chartData.NumYBins;
      this.updateXBins;
      this.updateYBins;

      this.chartTypeChange(this.selectedChartTypeName);
      this.updateAxisSelections();
      localStorage.setItem("savedChartData", JSON.stringify(chartData));
    },

    updateAxisSelections() {
      this.handleXAxisSelection();
      this.handleYAxisSelection();
      this.handleZAxisSelection();
    },

    findXAxisData() {
      if (this.tableData && this.selectedXAxisKey) {
        const columnData = this.tableData.map(
          (row) => row[this.selectedXAxisKey]
        );
        return columnData;
      }
      return [];
    },

    findYAxisData() {
      if (this.tableData && this.selectedYAxisKey.length > 0) {
        const columnData = this.selectedYAxisKey.map((col) => {
          return this.tableData.map((row) => row[col]);
        });
        return columnData;
      }
      return [];
    },

    findZAxisData() {
      if (this.tableData && this.selectedZAxisKey) {
        const columnData = this.tableData.map(
          (row) => row[this.selectedZAxisKey]
        );
        return columnData;
      }
      return [];
    },

    findcolorGroupData() {
      if (this.tableData && this.selectedColorGroupKey) {
        const columnData = this.tableData.map(row => row[this.selectedColorGroupKey]);
        const uniqueValues = [...new Set(columnData.filter(item => item !== undefined && item !== null))];
        return uniqueValues;
      }
      return [];
    },

    handleXAxisSelection() {
      if (this.selectedXAxisKey) {
        const selectedColumn = this.xAxisItems.find(
          (item) => item.name === this.selectedXAxisKey
        );
        this.xAxisData = this.findXAxisData();
        this.$emit("x-axis-selected", { data: this.xAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedXAxisKey });
        this.handleGroupSelection()
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      }
    },

    handleYAxisSelection() {
      if (this.selectedYAxisKey.length > 0) {
        if (this.selectedYAxisKey.length > 1) {
          this.selectedColorGroupKey = null;
        }
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map((key) => {
          const selectedColumn = this.yAxisItems.find(
            (item) => item.name === key
          );
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
        this.handleGroupSelection();
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.yAxisData = [];
        this.$emit("y-axis-selected", { data: [], displayNames: [] }); // Emit empty data
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      }
    },

    handleZAxisSelection() {
      if (this.selectedZAxisKey) {
        const selectedColumn = this.zAxisItems.find(
          (item) => item.name === this.selectedZAxisKey
        );
        this.zAxisData = this.findZAxisData();
        this.$emit("z-axis-selected", { data: this.zAxisData, displayName: selectedColumn ? selectedColumn.displayName : this.selectedZAxisKey });
        this.handleGroupSelection();
      }
      else {
        this.colorGroupData = [];
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      }
    },

    handleGroupSelection() {
      if (this.selectedColorGroupKey && this.selectedColorGroupKey.length > 0) {
        this.colorGroupData = this.findcolorGroupData();

        if (this.selectedChartTypeName != "Histogram") {
          // Check if yAxisData is present
          if (!this.yAxisData.length) {
            return;
          }
        }

        // Check if xAxisData is present
        if (!this.xAxisData.length) {
          return;
        }

        if (this.selectedYAxisKey.length > 1) {
          this.selectedYAxisKey = this.selectedYAxisKey.slice(0, 1);

          const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
            const selectedColumn = this.yAxisItems.find(item => item.name === key);
            return selectedColumn ? selectedColumn.displayName : key;
          });
          this.$emit("y-axis-selected", { displayNames: yAxisDisplayNames });
        }

        // Initialize an object to store grouped yAxisData based on uniqueValues
        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        // Initialize the grouped arrays for each unique value
        this.colorGroupData.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        // Iterate through tableData and group yAxisData and xAxisData
        this.tableData.forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKey];

          if (this.colorGroupData.includes(colorGroupValue)) {
            // Collect yAxis data
            const yAxisRowData = this.selectedYAxisKey.map(key => row[key]);
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            // Collect xAxis data
            const xAxisRowData = row[this.selectedXAxisKey];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            // Collect zAxis data
            const zAxisRowData = row[this.selectedZAxisKey];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });
        let selectedColorGroupKey = this.selectedColorGroupKey

        // Emit the grouped yAxis and xAxis data
        this.$emit("grouped-y-axis-data", (groupedYAxisData));
        this.$emit("grouped-x-axis-data", (groupedXAxisData));
        this.$emit("grouped-z-axis-data", (groupedZAxisData));
        this.$emit("color-group-key", (selectedColorGroupKey));
      }
      else {
        // Handle case when no items are selected for the y-axis
        this.colorGroupData = [];
        this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
        this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
        this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
        this.yAxisData = this.findYAxisData();
        const yAxisDisplayNames = this.selectedYAxisKey.map(key => {
          const selectedColumn = this.yAxisItems.find(item => item.name === key);
          return selectedColumn ? selectedColumn.displayName : key;
        });
        this.$emit("y-axis-selected", { data: this.yAxisData, displayNames: yAxisDisplayNames });
      }
    },

    resetAxisSelections() {
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedColorGroupKey = null;
      this.$emit("grouped-y-axis-data", { groupedYAxisData: [] });
      this.$emit("grouped-x-axis-data", { groupedXAxisData: [] });
      this.$emit("grouped-z-axis-data", { groupedZAxisData: [] });
      this.xAxisData = [];
      this.$emit("x-axis-selected", { data: this.xAxisData });
      this.yAxisData = [];
      this.$emit("y-axis-selected", { data: this.yAxisData });
      this.zAxisData = [];
      this.$emit("z-axis-selected", { data: this.zAxisData });
    },

    getChartTypes() {
      this.loadingChartType = true;
      api
        .get("public/api/v1/charts/type")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.chart_types;
            this.chartTypesNames = response.data.data.chart_types.map(
              (chart) => chart.Name
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.loadingChartType = false;
        });
    },
    getTableTypes() {
      this.showLoaders = true;
      this.loadingTableType = true;
      api
        .get("public/api/v1/tables/type")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.tableTypes = response.data.data.tabletypes;
            this.tableTypesNames = response.data.data.tabletypes.map(
              (chart) => chart.table
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingTableType = false;
        });
    },
    createChart() {
      this.createLoading = true;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );
      // Initialize YAxisColumns as an array and include selectedYAxisKey values if showYAxis is true
      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];

      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }
      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: "private",
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        // requestData.data.chart.GroupColumn = ""
      }
      api
        .post("public/api/v1/charts", requestData)
        .then((response) => {
          if (response.data.statusCode === 201) {
            this.createLoading = false;
            this.accessLevel = requestData.data.chart.AccessLevel;
            this.showSnackbar(response.data.message, "success");
            this.getSavedCharts();
            // this.resetChartData();
            this.$emit("chart-saved-studio", requestData.data.chart);
            this.createNewChart = true;
            this.selectedSavedChartName = requestData.data.chart.name;
            let chartId = response.data.data;
            this.getChartById(chartId);
          }
        })
        .catch((error) => {
          this.createLoading = false;
          this.showSnackbar(error.response.data.error, "error");
        });
    },
    async deleteChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.deleteLoading = true;
      const chartId = selectedChart.ID;

      try {
        const response = await api.delete(`public/api/v1/charts/${chartId}`);

        if (response.data.statusCode === 200) {
          this.deleteLoading = false;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          this.resetChartData();
          this.chartSaved = true;
          var chartSaved = this.chartSaved;
          this.$emit("chart-saved", chartSaved);
        }
      } catch (error) {
        this.deleteLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },

    async updateChart() {
      const selectedChart = this.savedCharts.find(
        (chart) => chart.Name === this.selectedSavedChartName
      );
      if (!selectedChart) {
        console.error("Selected chart not found.");
        return;
      }
      this.updateLoading = true;
      const chartId = selectedChart.ID;
      const chartType = this.selectedChartTypeName
        ? this.selectedChartTypeName
        : "";
      const tableType = this.selectedTableTypeName
        ? this.selectedTableTypeName
        : "";
      const selectedChartType = this.chartTypes.find(
        (chart) => chart.Name === chartType
      );

      let YAxisColumns = this.showYAxis ? [...this.selectedYAxisKey] : [];

      // Add GroupColumn data to YAxisColumns if available
      if (this.showColorGroup && this.selectedColorGroupKey) {
        YAxisColumns.push(this.selectedColorGroupKey);
      }

      const requestData = {
        data: {
          chart: {
            name: this.chartTitle,
            ChartType: chartType,
            TableType: tableType,
            XAxisColumn: this.selectedXAxisKey,
            AccessLevel: "private",
          },
        },
      };
      if (this.showYAxis) {
        requestData.data.chart.YAxisColumns = YAxisColumns;
      }
      if (this.showZAxis) {
        requestData.data.chart.ZAxisColumn = this.selectedZAxisKey;
      }
      if (selectedChartType && selectedChartType.has_x_bins) {
        requestData.data.chart.NumXBins = this.xBinsAsNumber;
      }
      if (selectedChartType && selectedChartType.has_y_bins) {
        requestData.data.chart.NumYBins = this.yBinsAsNumber;
      }
      if (this.showColorGroup && this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = this.selectedColorGroupKey
      }
      if (this.showColorGroup && !this.selectedColorGroupKey) {
        requestData.data.chart.GroupColumn = ""
      }
      try {
        const response = await api.put(
          `public/api/v1/charts/${chartId}`,
          requestData
        );

        if (response.data.statusCode === 200) {
          this.updateLoading = false;
          this.accessLevel = requestData.data.chart.AccessLevel;
          this.showSnackbar(response.data.message, "success");
          this.getSavedCharts();
          // this.resetChartData();
          this.chartSaved = true;
          var chartSaved = this.chartSaved;
          this.$emit("chart-saved", chartSaved);
          this.getChartById(chartId);
        }
      } catch (error) {
        this.updateLoading = false;
        this.showSnackbar(error.response.data.error, "error");
      }
    },
    async getChartById(chartId) {
      try {
        const response = await api.get(
          `public/api/v1/charts/${chartId}`
        );

        if (response.data.statusCode === 200) {
          let chartDataById = response.data.data.chart;
          localStorage.setItem("savedChartData", JSON.stringify(chartDataById));
        }
      } catch (error) {
        console.log(error)
      }
    },
    resetChartData() {
      this.showLoaders = true;
      this.chartTitle = "";
      this.resetAxisSelections();
      this.$emit("chart-type-changed", "");
      this.$emit("chart-title", "");
      this.showXBinsInput = false;
      this.showYBinsInput = false;
      this.showZAxis = false;
      this.showColorGroup = false;
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedSavedChartName = null;
      this.showLoaders = false;
      this.createNewChart = false;
      localStorage.removeItem("savedChartData");
    },
    getSavedCharts() {
      this.showLoaders = true;
      this.loadingSavedCharts = true;
      api
        .get("public/api/v1/charts")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.savedCharts = response.data.data.charts;
            this.savedChartsNames = response.data.data.charts.map(
              (chart) => chart.Name
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoaders = false;
          this.loadingSavedCharts = false;
        });
    },
  },
  mounted() {
    if (this.$route.path === '/Fluidsdata-studio') {
      const savedChartData = localStorage.getItem("savedChartData");
      if (savedChartData) {
        const chartData = JSON.parse(savedChartData);
        if (chartData) {
          this.savedChartChangeLoad(chartData);
        }
      }
    }
    if (this.$route.path === '/Visualization-&-Dashboards') {
      this.showInputs = true;
      this.chartTitle = "";
      this.selectedTableTypeName = null;
      this.selectedChartTypeName = null;
      this.selectedXAxisKey = [];
      this.selectedYAxisKey = [];
      this.selectedZAxisKey = [];
      this.selectedColorGroupKey = null;
    }
    this.getChartTypes();
    this.getTableTypes();
    this.getSavedCharts();
  },
};
</script>
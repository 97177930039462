<template>
  <div class="auth-left-wrapper d-block d-sm-flex align-center justify-center w-100 mb-sm-0">
    <div class="wrap-content py-3 py-sm-0">
      <div class="img-wrap">
        <v-img aspect-ratio="16/9" :src="image" alt="logo"></v-img>
      </div>
      <p class="main-text text-center">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Left",
  data() {
    return {
      image: require("../../../assets/Images/Auth/auth-img.png"),
      text: "Fluids Characterization AI Software",
    };
  },
};
</script>
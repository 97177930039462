<template>
  <div>
    <div v-if="(!data || data.length === 0) && loading">
      <SingleChartLoader></SingleChartLoader>
    </div>
    <div v-if="(!data || data.length === 0) && !loading" class="text-center py-5">
      <h4>Chart data is not available.</h4>
    </div>
    <div v-else-if="data && data.length > 0 && yDataAvailable === true"
      class="chart-column liberated-gas-chart-column">
      <div v-if="isMounted" class="main-chart mr-md-2">
        <div :ref="chartId"></div>
        <div v-if="chartData && chartData.length > 0">
          <v-btn @click="openModal" flat size="small" class="chart-preview-btn2 text-none">Expand</v-btn>
        </div>
      </div>
      <v-dialog v-model="modal" width="100vw" class="multi-chart-preview-div">
        <v-card>
          <div class="modal-header header-div">
            <v-spacer></v-spacer>
            <v-btn @click="closeModal" flat icon class="chart-preview-close-btn" size="x-small">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <div :ref="modalChartId"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="data && data.length > 0 && yDataAvailable === false && !loading" class="text-center pt-5 pb-12">
      <h4>Chart data is not available.</h4>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import SingleChartLoader from "../Loaders/SingleChartLoader.vue";

export default {
  name: "ProducedGasComponentChart",
  components: {
    SingleChartLoader,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // toggleFraction: {
    //   type: Boolean,
    //   default: true,
    // },
  },
  data() {
    return {
      tenantId: localStorage.getItem("tenantId"),
      chartId: "lineChart",
      chartData: [],
      modal: false,
      modalChartId: "modalLineChart",
      chartTitle: "Vapour Compositions at Various Pressure Steps",
      isMounted: false,
      yDataAvailable: true,
    };
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal !== oldVal && newVal.length > 0) {
        this.plotChart();
      }
    },
  },
  methods: {
    plotChart() {
      const differentiaLiberationData = this.data;
      const vaporMoleFractionsByComponent = {};
      // const vaporMassFractionsByComponent = {};
      differentiaLiberationData.forEach((step) => {
        const fluidComponent = step.fluidComponentReference;
        const vaporMoleFraction = step.vaporMoleFraction;
        // const vaporMassFraction = step.vapor_MassFraction;
        const stepPressure = step.stepPressure;

        if (!vaporMoleFractionsByComponent[stepPressure]) {
          vaporMoleFractionsByComponent[stepPressure] = [];
        }

        vaporMoleFractionsByComponent[stepPressure].push({ component: fluidComponent, moleFraction: vaporMoleFraction });

        // if (!vaporMassFractionsByComponent[stepPressure]) {
        //   vaporMassFractionsByComponent[stepPressure] = [];
        // }

        // vaporMassFractionsByComponent[stepPressure].push({ component: fluidComponent, massFraction: vaporMassFraction });
      });

      // if (this.toggleFraction === true) {
      this.chartData = Object.entries(vaporMoleFractionsByComponent).map(([component, values]) => {
        const xData = values.map(entry => entry.component);
        const yData = values.map(entry => entry.moleFraction);

        if (yData.every(value => value === undefined) || yData.every(value => value === null) || !yData) {
          this.yDataAvailable = false;
        }
        else {
          this.yDataAvailable = true;
        }

        return {
          name: component,
          x: xData,
          y: yData,
        };
      });
      // } else if (this.toggleFraction === false) {
      //   this.chartData = Object.entries(vaporMassFractionsByComponent).map(([component, values]) => {
      //     const xData = values.map(entry => entry.component);
      //     const yData = values.map(entry => entry.massFraction);

      //     if (yData.every(value => value === undefined) || yData.every(value => value === null) || !yData) {
      //       this.yDataAvailable = false;
      //     }
      //     else {
      //       this.yDataAvailable = true;
      //     }

      //     return {
      //       name: component,
      //       x: xData,
      //       y: yData,
      //     };
      //   });
      // }

      this.$nextTick(() => {
        if (this.isMounted && this.$refs[this.chartId]) {
          this.plotChartWithData();
        }
      });
    },

    plotChartWithData() {
      this.$nextTick(() => {
        const traces = this.chartData.map((series) => ({
          x: series.x,
          y: series.y,
          type: "scatter",
          mode: "lines+markers",
          name: series.name,
          hovertemplate: `%{y:.3f}<extra></extra>`
        }));

        const yAxisTitle = "Mole%";

        const layout = {
          title: {
            text: this.chartTitle,
            font: { size: 11 },
            x: 0.1,
          },
          xaxis: {
            title: {
              text: "Component",
              standoff: 10,
            },
          },
          yaxis: { title: yAxisTitle },
          legend: { x: 1, y: 0.2, orientation: "v" },
        };

        Plotly.newPlot(this.$refs[this.chartId], traces, layout);
      });
    },

    plotModalChart() {
      this.$nextTick(() => {
        const traces = this.chartData.map((series) => ({
          x: series.x,
          y: series.y,
          type: "scatter",
          mode: "lines+markers",
          name: series.name,
          hovertemplate: `%{y:.3f}<extra></extra>`
        }));

        const yAxisTitle = "Mole%";

        const layout = {
          title: {
            text: this.chartTitle,
            font: { size: 12 },
          },
          xaxis: {
            title: {
              text: "Component",
              standoff: 10,
            },
          },
          yaxis: { title: yAxisTitle },
          legend: { x: 1, y: 0.2, orientation: "v" },
        };

        const config = {
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d']
        };

        Plotly.newPlot(this.$refs[this.modalChartId], traces, layout, config);
      });
    },


    openModal() {
      this.modal = true;
      this.$nextTick(() => {
        this.plotModalChart();
      });
    },

    closeModal() {
      this.modal = false;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>